import React from "react";

import { useInitialLoad } from "../../hooks/use-initial-load"

export const Message = (props) => {
  const {children, height, width} = props;

  const initialLoad = useInitialLoad()

  return (
    <div className="message-container">
      <div
        className="animate-opacity message-box text"
        style={{
          maxHeight: height,
          maxWidth: width,
          opacity: initialLoad ? 1 : 0,
        }}
      >
        <div className="message-content text">
          {children}
        </div>
      </div>
    </div>
  )
};
