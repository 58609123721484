import React, { useState } from "react"

import "./index.css"

import SEO from "../components/seo"
import { Message } from "../components/message"
import { Menu } from "../components/menu"
import { RegistryBackground } from "../components/registry/background"
import { useInitialLoad } from "../hooks/use-initial-load"

const SNAIL_MAIL = "SNAIL_MAIL"
const E_TRANSFER = "E_TRANSFER"

const BASE_HEIGHT = 500
const heights = {
  [E_TRANSFER]: 115,
  [SNAIL_MAIL]: 115,
}

const descriptions = {
  [E_TRANSFER]: (
    <div style={{ marginTop: "24px" }}>
      <div style={{ marginBottom: "12px" }}>You can send an e-transfer to:</div>
      <div style={{ marginBottom: "24px" }}>baedrienne.wedding@gmail.com</div>
      <div style={{ borderBottom: "1px white solid" }}/>
    </div>
  ),
  [SNAIL_MAIL]: (
    <div style={{ marginTop: "24px" }}>
      <div style={{ marginBottom: "12px" }}>You can mail your gift to:</div>
      <div style={{ marginBottom: "0px" }}>2928 Burgess Drive NW</div>
      <div style={{ marginBottom: "24px" }}>Calgary AB T2L 1J2</div>
      <div style={{ borderBottom: "1px white solid" }}/>
    </div>
  ),
}

const Content = () => {
  const [type, setType] = useState()
  const initialLoad = useInitialLoad()

  let height = BASE_HEIGHT
  if (type) {
    height = height + (heights[type] || 0)
  }

  return (
    <Message
      className="animate-opacity"
      height={height}
      style={{ opacity: initialLoad ? 1 : 0 }}
      width={275}
    >
      <div style={{ marginBottom: "24px" }}>Dear friends and family,</div>
      <div style={{ marginBottom: "24px" }}>We are so grateful for the love and support we have received as we prepare
        for
        the big day.
      </div>
      <div style={{ marginBottom: "24px" }}>If you would like to present us with a financial gift as we begin our
        marriage, any
        amount is greatly appreciated.
      </div>
      <div style={{ marginBottom: "24px" }}>Either way, your prayers, or even a simple card, is more than enough. We are
        so thankful for each
        and every one of you.
      </div>
      <div>Love,</div>
      <div style={{ marginBottom: "24px" }}>Bradley & Edrienne</div>

      <div style={{ alignItems: "center", display: "flex", marginBottom: "12px" }}>
        <div>Ways to Give</div>
        <div style={{ borderBottom: "1px white solid", flexGrow: 1, marginLeft: "8px" }}/>
      </div>

      <div style={{ display: "flex" }}>
        <div className="hover-button" onClick={() => setType(SNAIL_MAIL)}
             style={{ cursor: "pointer", marginRight: "12px" }}>Snail Mail
        </div>
        <div style={{ marginRight: "12px" }}>|</div>
        <div className="hover-button" onClick={() => setType(E_TRANSFER)} style={{ cursor: "pointer" }}>E - Transfer
        </div>
        <div style={{ margin: "0px 12px" }}>|</div>
        <a
          className="hover-button"
          href={"https://paypal.me/baedrienne"}
          rel="noopener noreferrer"
          target="_blank"
        >Paypal</a>
      </div>

      {descriptions[type]}
    </Message>
  )
}

const Page = (props) => {
  const initialLoad = useInitialLoad()

  return (
    <>
      <Menu location={props.location}/>
      <Content/>
      <div className="container overlay"/>
      <div className="animate-blur container" style={{ filter: "blur(0px)" }}>
        <RegistryBackground
          className="animate-opacity full-page-background"
          style={{ opacity: initialLoad ? 1 : 0 }}
        />
      </div>
    </>
  )
}

const Registry = (props) => (
  <>
    <SEO title="Registry"/>
    <Page {...props}/>
  </>
)

export default Registry
